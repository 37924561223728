// @ts-ignore
import _ from 'lodash';
import { AnalyticsEventType, getAnalyticsService } from 'ui-common';
import { UIConfig } from '../api';
import { getCustomerIdFromUserInfo } from '../auth/auth-utils';

export class Analytics {
  static appName = 'one-platform-external-ui';

  static isAnalyticsEnabled = (config: UIConfig) => {
    return !_.isEmpty(config.googleAnalyticsTrackingId);
  };

  static initTracking = (trackingId: string, userInfo: any) => {
    Analytics.enabled = true;
    Analytics.trackingId = trackingId;
    Analytics.customerId = getCustomerIdFromUserInfo(userInfo);
    Analytics.userId = userInfo.sub;
    Analytics.service = getAnalyticsService(Analytics.trackingId, Analytics.appName);
    Analytics.service.setupBasicTracking({ id: Analytics.userId });
  };

  static trackEvent = (elemId: string, eventType: AnalyticsEventType) => {
    if (Analytics.enabled) {
      const event = {
        type: eventType,
        payload: {
          id: elemId,
          name: elemId,
          type: eventType,
          customerId: Analytics.customerId,
          oktaId: Analytics.userId,
        },
      };
      Analytics.service.trackEvent(eventType, event);
    }
  };

  private static service: any;
  private static enabled = false;

  private static trackingId: string;
  private static customerId: string;
  private static userId: string;
}
